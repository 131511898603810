import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, merge } from 'lodash';

// Constants
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import { CARD_VIEW, LEAD_FORM_VIEWS, THANK_YOU_VIEWS } from 'site-modules/shared/constants/lead-form/lead-form-params';

// Components
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';
import { sendMarketoThankYouEmail } from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
// Utils
import { getMultiDigitalRetailLeadFormPropsCommon } from './multi-digital-retail-offer-type-lead-form-config-utils';

export function MultiDigitalRetailOfferTypeLeadFormSrpNewUI({ params, options, pageName, visitorId, isUserSetZip }) {
  const postProcessMethods = sendMarketoThankYouEmail;
  const subtitle = (
    <div className="mb-1_5">
      <div className="mb-1">
        Did you know? You should know exactly what you should pay for a car before you test drive it.
      </div>
      <div> We&apos;ll show you what to pay on the next page.</div>
    </div>
  );

  const newFormProps = {
    customConfig: {
      tags: null,
      leadFormComponent: LEAD_FORM_VIEWS.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW,
      leadFormConfig: {
        cardView: CARD_VIEW.DEALER_VIN_SRP_NEW,
        isPriceChecker: true,
        title: '[savingsValue=0?Save with Edmunds:Save {savingsValue} with Edmunds]',
        titleClassName: 'text-blue-50 fw-bold size-24 mb-1 mb-md-1_5',
        subtitle,
      },
      thankYouComponent: THANK_YOU_VIEWS.SRP_PRICE_CHECKER,
      thankYouConfig: {
        isNewVinOnSrp: true,
      },
      apiParams: {
        enableAdvertisedPrice: true,
      },
    },
    config: {
      ...LEAD_FORM_CONFIGS.UNIFIED_PURCHASE,
    },
  };

  const formProps = merge(
    getMultiDigitalRetailLeadFormPropsCommon({
      params,
      options,
      visitorId,
      pageName,
      postProcessMethods,
      isNewVin: true,
      isUserSetZip,
    }),
    newFormProps
  );

  return <UnifiedLeadFormAsync {...formProps} />;
}

MultiDigitalRetailOfferTypeLeadFormSrpNewUI.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  pageName: PropTypes.string,
  visitorId: PropTypes.string,
  isUserSetZip: PropTypes.bool,
};

MultiDigitalRetailOfferTypeLeadFormSrpNewUI.defaultProps = {
  pageName: '',
  visitorId: null,
  isUserSetZip: false,
};

export const mapStateToProps = state => ({
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  visitorId: get(state, 'visitor.id'),
  isUserSetZip: !!get(state, 'visitor.location.userSet'),
});

export const MultiDigitalRetailOfferTypeLeadFormSrpNew = connect(mapStateToProps)(
  MultiDigitalRetailOfferTypeLeadFormSrpNewUI
);
