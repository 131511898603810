import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';

import './color-swatch.scss';

export function createRGBString(color) {
  const { rgb, r, g, b } = color || {};
  let rgbString;

  if (rgb) {
    rgbString = `rgb(${rgb})`;
  } else if (r != null && g != null && b != null) {
    rgbString = `rgb(${r}, ${g}, ${b})`;
  }

  return rgbString;
}

export class ColorSwatch extends Component {
  static propTypes = {
    color: PropTypes.shape({
      name: PropTypes.string,
      r: PropTypes.number,
      g: PropTypes.number,
      b: PropTypes.number,
      rgb: PropTypes.string,
    }),
    displayColorName: PropTypes.bool,
    displayTooltip: PropTypes.bool,
    title: PropTypes.string,
    id: PropTypes.string,
    classNames: PropTypes.string,
    colorNameClasses: PropTypes.string,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    color: {},
    displayColorName: true,
    displayTooltip: false,
    title: '',
    id: `color-swatch-id`,
    classNames: '',
    colorNameClasses: '',
    style: {},
  };

  getText() {
    const { color, title } = this.props;

    const { name } = color || {};
    const colorName = name || '';
    return title || colorName;
  }

  renderColor = (colorProps, ref) => {
    const { classNames, style, color, displayColorName, displayTooltip } = this.props;
    const rgb = createRGBString(color);
    const hasColor = !!rgb;

    return (
      <span
        ref={ref}
        title={displayColorName || displayTooltip ? null : this.getText()}
        className={
          classnames(
            'color-swatch mr-0_5',
            { 'text-center unknown-color': !hasColor },
            classNames
          ) /* need to check what is ready for unknown colors */
        }
        style={{ backgroundColor: rgb || 'transparent', ...style }}
        {...colorProps}
      >
        {!hasColor && <span className="small d-block pos-r line-through" />}
      </span>
    );
  };

  render() {
    const { displayColorName, id, colorNameClasses, displayTooltip } = this.props;
    const text = this.getText();

    /**
     * For simplification of this shared component, we have refactored it so that
     * text is visible or a tooltip is used.
     *
     * Before we did this, we had a mix of states where tooltips and text were showing up, which was
     * problematic for screen readers because it would duplicate the text that was read.
     *
     * If you need to tweak your presentation, you can probably do this via CSS in your parent component
     * without needing to change the markup.  Please test with a screen reader and keyboard-only and consider
     * color-blind users.
     *
     * Try to keep this shared component free of confusing and difficult to maintain props.
     */
    return (
      <span>
        {!displayTooltip && this.renderColor()}
        {displayTooltip && (
          <TooltipItem
            id={id}
            item={{ text, placement: 'top' }}
            className="color-tooltip"
            iconClassName="d-block w-100 h-100"
            innerClassName="small"
            delay={{ show: 250 }}
            renderTarget={this.renderColor}
            trigger="hover focus"
            tooltipContainerTag="span"
            useUniqId
          />
        )}
        {displayColorName && <span className={colorNameClasses}>{text}</span>}
        {!displayColorName && !displayTooltip && <span className="visually-hidden">{text}</span>}
      </span>
    );
  }
}
